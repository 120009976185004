import { defineStore } from 'pinia';

import { fetchResults } from '@petlove/lion/graphql/requests/search';
import { useAuthStore } from '@petlove/lion/store/auth';

let debounce = null;

export const useSearchStore = defineStore('search', {
	state: () => ({
		loading: false,
		results: {
			hits: [],
			suggestions: [],
		},
	}),
	getters: {
		products: state => state.results?.hits || [],
		suggestions: state => state.results?.suggestions || [],
	},
	actions: {
		setLoading(payload) {
			this.loading = payload;
		},
		setResults(payload) {
			this.results = payload;
		},
		async fetchResults(text) {
			if (!text) return;

			const nuxtApp = useNuxtApp();
			const authStore = useAuthStore();
			this.setLoading(true);

			const getEndpoint = () => {
				const config = useRuntimeConfig();
				const { apiGwURL } = config?.public || {};
				if (!apiGwURL) throw new Error('Missing API gateway endpoint env');
				return window.location.host.includes('local') ? '/api-gw' : apiGwURL;
			};

			const getFromSessionStorage = () => {
				const search = sessionStorage.getItem('search');
				return search ? JSON.parse(search) : {};
			};

			try {
				const search = getFromSessionStorage();
				if (search[text]) {
					this.setResults(search[text] || {});
					this.setLoading(false);
					return;
				}

				const endpoint = getEndpoint();
				const publicId = authStore.getSession?.public_id || null;
				clearTimeout(debounce);
				debounce = setTimeout(() => {
					fetchResults(text, endpoint, publicId).then(({ autocomplete }) => {
						this.setResults(autocomplete || {});
						if (autocomplete?.hits?.length > 0) {
							sessionStorage.setItem(
								'search',
								JSON.stringify({ ...search, [text]: autocomplete }),
							);
						}
					});
				}, 1000);
			}
			catch (error) {
				nuxtApp.$honeybadger?.notify(error, {
					component: 'SearchState',
					action: 'fetchResults',
					context: { text },
				});
			}
			finally {
				this.setLoading(false);
			}
		},
	},
});
