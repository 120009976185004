import { autocomplete, preTypingQuery } from '../../queries/search'

export async function fetchPreTyping(endpoint) {
  try {
    const { data } = await $fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: preTypingQuery,
      }),
    })
    return data?.preTyping?.highlightTerms || []
  }
  catch (error) {
    return error
  }
}

export async function fetchResults(text, endpoint, publicId) {
  const { data } = await $fetch(endpoint, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: autocomplete(text, publicId),
    }),
  })
  return data
}
