<template>
	<CaBadge
		:value="bullet"
		color="accent"
		position="top"
	>
		<CaLink
			icon="cart"
			aria-label="carrinho"
			href="/carrinho"
			no-padding
			class="cart-link"
			:color="!isDesktop ? 'white' : null"
		/>
	</CaBadge>
</template>

<script setup>
import { useCartStore } from '@petlove/lion/store/cart';

const cartStore = useCartStore();
const { bullet } = storeToRefs(cartStore);
const { isDesktop } = useDevice();

onMounted(() => {
	cartStore.syncBullet();
});
</script>

<style scoped>
:deep(.cart-link) .link__icon {
  margin-right: unset;
}
</style>
