export const preTypingQuery = `
query {
  preTyping(limit: 10, type: "all") {
    highlightTerms {
      term
    }
  }
}
`;

export const searchAsYouTypeQuery = (text, publicId) => `
query {
  searchAsYouType(term: "${text}", numSuggestions: 6, numHits: 9, publicId: "${publicId}") {
    hits {
      brand
      category
      collections
      discount
      image
      images {
        hdNoExtent
        small
      }
      inStock
      name
      omnichannel
      otherVariants {
        inStock
        shortName
        sku
      }
      path
      prices {
        list
        original
        subscriber
      }
      rating
      shortName
      similarProducts {
        products {
          image
          inStock
          label
          sku
        }
        group
      }
      slug
      sku
      skuMaster
      skuSpec
      tags {
        color
        name
        position
      }
    }
    categorySuggestions {
      term
      weight
      facets {
        name
        key
        type
        values {
          name
          qty
        }
      }
    }
    historySuggestions {
      term
      highlightedTerm
      weight
    }
    suggestions {
      term
      highlightedTerm
      weight
    }
  }
}`;

export const autocomplete = (text, publicId) => `
query {
  autocomplete(term: "${text}", numSuggestions: 5, numHits: 5, publicId: "${publicId}") {
    hits {
      image
      name
      path
      slug
      prices {
        original
      }
    }
    suggestions {
      term
    }
  }
}
`;
