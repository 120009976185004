<script setup>
import { getCookie, deleteCookie, setCookie } from '@petlove/lion/libs/cookies';

const bannerData = ref(null);
const showPartnershipRulesModal = ref(false);
const { isDesktop } = useDevice();
const fontSize = ref(isDesktop ? 'font-body-s' : 'font-body-xs');

const { hasGoogleCampaign, utmMedium, utmSource } = getParams();
const hasPartnershipUtms
  = utmSource && (utmMedium === 'parcerias' || utmMedium === 'lojas_online');
const partner = hasPartnershipUtms ? utmSource : getCookie('ADVANTAGE_NETWORK');
const mediumType = hasPartnershipUtms
	? utmMedium
	: getCookie('ADVANTAGE_NETWORK_MEDIUM');

if (
	(hasPartnershipUtms
		&& !(utmMedium === 'parcerias' || utmMedium === 'lojas_online'))
	|| (utmMedium && getCookie('ADVANTAGE_NETWORK_MEDIUM') !== utmMedium)
) {
	deleteCookie('ADVANTAGE_NETWORK');
	deleteCookie('ADVANTAGE_NETWORK_MEDIUM');
}

if (
	mediumType === 'parcerias'
	|| (mediumType === 'lojas_online' && !hasGoogleCampaign)
) {
	try {
		const partnerData = await getPartner();
		const hasExpireDays = partner.expiration || partner.cookie_expire_at;

		if (partner) {
			setBanner(partnerData);
			setCookies(hasExpireDays);
		}
	}
	catch (error) {
		console.error('Error getting partner data', error);
	}
}

function getParams() {
	const params = new URLSearchParams(window.location.search);
	const hasGoogleCampaign = params.get('gclid');
	const utmSource = params.get('utm_source');
	const utmMedium = params.get('utm_medium');

	return { hasGoogleCampaign, utmSource, utmMedium };
}

async function getPartner() {
	if (!partner) return;

	const type = mediumType === 'parcerias' ? 'companies' : 'whitelabels';
	const { protocol, host } = window.location;
	const url = `${protocol}//${host}/service/${type}/${partner}`;

	return $fetch(url);
}

function setCookies(expire) {
	const advantageNetwork = getCookie('ADVANTAGE_NETWORK');
	const advantageNetworkMedium = getCookie('ADVANTAGE_NETWORK_MEDIUM');

	if (!advantageNetwork && advantageNetwork !== partner) {
		setCookie('ADVANTAGE_NETWORK', partner, expire);
	}
	if (!advantageNetworkMedium || advantageNetworkMedium !== mediumType) {
		setCookie('ADVANTAGE_NETWORK_MEDIUM', mediumType, expire);
	}
}

function setBanner(data) {
	bannerData.value = {
		isActive: data.active || data.banner || false,
		name: data.name || data.slug || '',
		image: data.logo_url || data.logo?.url || '',
		message: data.message || '',
		regulation: data.regulation || '',
	};
}

function tooglePartnershipRulesModal() {
	showPartnershipRulesModal.value = !showPartnershipRulesModal.value;
}
</script>

<template>
	<!-- Banner de parcerias e whitelabel -->
	<div
		v-if="bannerData?.isActive"
		class="full-width flex justify-content-center align-items-center p-2 gap-5 banner"
	>
		<img
			v-if="bannerData?.image"
			class="image"
			:src="bannerData?.image"
			:alt="`Logomarca da ${bannerData?.slug}`"
			width="90px"
		>
		<span
			class="color-white"
			:class="fontSize"
		>Parceria entre Petlove e {{ bannerData?.name }}
			<span
				v-if="bannerData?.message"
				v-html="bannerData?.message"
			/>
		</span>
		<CaLink
			v-if="bannerData?.regulation"
			color="white"
			no-padding
			:class="fontSize"
			@click="tooglePartnershipRulesModal()"
		>
			Veja condições
		</CaLink>
	</div>

	<!-- Modal de regras da parceria -->
	<CaModal
		:show="showPartnershipRulesModal"
		title="Condições da parceria"
		btn-label="Entendi"
		@close="tooglePartnershipRulesModal()"
		@btn-click="tooglePartnershipRulesModal()"
	>
		<div v-html="bannerData?.regulation" />
	</CaModal>
</template>

<style scoped>
.banner {
  background-color: rgb(32, 38, 44);
  height: 48px;
}

.image {
  width: 90px;
  height: 100%;
  object-fit: contain;
}
</style>
