<template>
  <div id="ca-zipcode">
    <CaModal
      v-cloak
      ref="ca-zipcode-modal"
      :show="showModal"
      title="Informe sua localizacão"
      :btn-label="btnLabel"
      :btn-disabled="!isValidZipcode"
      @close="handleCaDialog"
      @keyup.enter="confirmZipcode"
      @btn-click="confirmZipcode"
    >
      <div>
        <slot>
          <p>Informe seu CEP e confira as melhores condições para você.</p>
          <small>
            As modalidades e tempo de entrega variam de acordo com a região.
          </small>
        </slot>
        <slot name="alert" />
        <div class="mt-6">
          <CaCardSelector
             v-model="selectedAddressId"
            v-for="(address, index) in addresses"
            type="radio"
            :name="'radio-' + index"
            :value="address.id"
            :key="address.id"
          >
            <span>{{ address.label }}</span>
            <br />
            <span
              >{{ address.displayZipcode }}, {{ address.city }} -
              {{ address.state }}</span
            >
          </CaCardSelector>
        </div>
        <div v-if="addresses && addresses.length">
          <ca-divisor />
          <p>Ou verifique outro CEP</p>
        </div>
        <div>
          <CaInputText
            v-model="inputSearch"
            class="mt-4"
            type="number"
            label="Informe seu CEP"
            mask="#####-###"
            placeholder="Informe seu CEP"
          />
        </div>
      </div>
    </CaModal>
  </div>
</template>

<script>
import {
  setCookie, getCookie, COOKIE_AUTOCOMPLETE_ZIPCODE } from '@petlove/lion/libs/cookies';
import { mapState, mapActions } from 'pinia';
import { customEvent } from '@petlove/lion/libs/analytics/events/customEvents';
import { useAuthStore } from '@petlove/lion/store/auth';
import { useShipmentStore } from '@petlove/lion/store/shipment';
import { useAddressStore } from '@petlove/lion/store/address';

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    btnLabel: {
      type: String,
      default: 'Continuar',
    },
    forceReload: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inputSearch: '',
      selectedAddressId: '',
      addresses: [],
    };
  },
  computed: {
    ...mapState(useAuthStore, ['isLogged']),
    ...mapState(useShipmentStore, { isSelectedZipcode: 'selectedZipcode' }),
    isValidZipcode() {
      return this.inputSearch.length === 9
    }
  },
  watch: {
    selectedZipcode(newValue) {
      this.inputSearch = newValue;
    },
  },
  async mounted() {
    const addressStore = useAddressStore();
    if (getCookie('PL_email') && this.isLogged) {
      try {
        this.addresses = await addressStore.fetchAddresses();
      } catch (e) {
        console.error('Fail to fetch addresses', e);
      }
    }

    this.inputSearch = getCookie('PL_zipcode');
  },
  methods: {
    ...mapActions(useShipmentStore, ['setSelectedZipcode']),
    handleCaDialog() {
      customEvent({
        event_category: 'click',
        event_action: 'modal-localizacao:botao:fechar',
        event_label: this.eventLabel,
      });

      this.showZipcodeInfoHeader = false;
      setCookie('PL_zipcodeInfoHeaderViewed', true);
      this.$emit('handlerModal', !this.showModal);
    },
    confirmZipcode() {
      if (!this.isValidZipcode) return;

      customEvent({
        event_category: 'click',
        event_action: 'modal-localizacao:botao:consultar-cep',
        event_label: this.eventLabel,
      });
      setCookie(COOKIE_AUTOCOMPLETE_ZIPCODE, 'false');
      const selectedAddress = this.addresses.find(
        (address) => address.id === this.selectedAddressId
      );
      this.inputSearch = selectedAddress?.displayZipcode || this.inputSearch;
      this.setSelectedZipcode(this.inputSearch);
      this.$emit('handlerModal', !this.showModal);
      this.$emit('handleZipcode', this.inputSearch);
      if (this.forceReload) {
        window.location.reload();
      } else {
        this.$emit('continue');
      }
    },
  },
};
</script>

<style lang="scss">
#ca-zipcode {
  footer button {
    width: 100%;
  }
  small {
    font-size: 80%;
  }

  .modal__container {
    max-width: 450px;
  }
}

</style>
